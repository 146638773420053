<template>
  <main id="home">
    <img class="next" src="../assets/chevron-right.svg" alt="next" @click="$emit('move', { transitionType: 'next', to: '/hej' })">
     <header id="mobile" v-if="mobile">
      <h1>Konrad är en mobilapp</h1>
      <p>Konrad är byggd med den senaste tekniken och är en sk. Progressiv Webbapplikation. För för bästa upplevese och funktionalitet bör den användas via mobilen och <b>läggas till på hemskärmen</b>.</p>
      <p>Hur du gör det hittar du <a class="link" href="https://www.youtube.com/watch?v=DBOR8mfdVZ8" target="_blank" >här</a> ( iOS ) och <a class="link" href="https://www.youtube.com/watch?v=kaXWohkYy0A" target="_blank">här</a> ( Android ).</p>
      <label><input type="checkbox" @change="hideModal">Visa inte igen</label>
    </header>
    <section class="content" v-hammer:swipe.left.right="goTo">
      <aside @click="profile">studieprofil</aside>
      <img src="../assets/icon-settings.svg" id="settings" alt="" @click="showInfo">
      <img src="../assets/konrad-fox.svg" alt="Konrad">
      <h1 class="main">Konrad</h1>
      <h4>Din guide i gymnasiedjungeln</h4>

      <img src="../assets/molndal-logo-white.svg" id="logo" alt="">
    </section>
    <pager :activePage="1" />
  </main>
</template>

<script>
import pager from '@/components/Pager';

export default {
  name: 'home',
  components: {
      pager
  },
  data() {
    return {
      mobile: true
    }
  },
  methods: {
    showInfo(){
      this.$parent.showInfo = true;
    },
    hideModal(){
      localStorage.setItem('konradmodal', JSON.stringify(this.mobile))
      this.mobile = false;
    },
    profile(){
      this.$emit('move', { transitionType: 'start', to: '/studieprofil' });
    },
    goTo(e){
      if(e.direction === 2) {
        this.$emit('move', { transitionType: 'next', to: '/hej' });
      }

      if(e.type == "click"){
        this.$emit('move', { transitionType: 'next', to: '/hej' });
      }
    }
  },
  beforeMount(){
     if(!localStorage['konradmodal']){
      this.mobile = true;
      } else {
        this.mobile = false;
      }
  }
}
</script>
<style lang="scss">
@import '../scss/variables';

#home {
  background: $darkgrey;

  .next {
    position: absolute;
    right: .5rem;
    top: calc(50% - 1.5rem);
    width: 3rem;
    animation: pulse infinite alternate-reverse 1.2s ease-in;

    @keyframes pulse {
      0% { opacity: .1; transform: translateX(0); }
      100% { opacity: .3; transform: translateX(.5rem); }
    }
  }

  #mobile {
    display: block;
    position: absolute;
    z-index: 9999;
    background: #222;
    color: white;
    width: 90%;
    top: 2rem;
    left: 5%;
    padding: 2rem;

    p {

      a.link {
      color: white !important;
      display: inline-block !important;
      padding: .125rem .5rem !important;
      text-decoration: none;
      border-radius: 3px;
      background: rgba($color: #FFF, $alpha: .2) !important;
      }
    }

     label {
        font-size: .8rem;
        margin: .5rem 0;
        display: flex;
        align-items: center;
      }

      input,textarea,select {
        appearance: none;
        border: 1px solid rgba($color: #fff, $alpha: .8);
        border-radius: $radius;
        padding: .5rem;
        font-size: 1rem;
        resize: none;
        transition: $transitionTime;

        &[type="checkbox"] {
          margin: .5rem .5rem .5rem 0;

          &:checked {
            background: #fff url('../assets/icon-close.png');
            background-size: contain;
          }

        }

        &:focus {
          outline: none;
          border: 1px solid rgba($color: #fff, $alpha: 1);
          background: rgba($color: #fff, $alpha: .05);
        }
      }
  }
/*
   #mobile {
     display: none;
  }

  @media (min-width: 480px) {
    #mobile {
      display: block;
      position: absolute;
      z-index: 9999;
      background: #222;
      color: white;
      width: 80%;
      top: 2rem;
      left: 10%;
      padding: 2rem;

      &:hover {
        cursor: pointer;
      }

    }
  }
*/
  .content {
    @extend %center-column;
    color: white;

    > aside {
      position: absolute;
      top: 1rem;
      left: 1rem;
      padding: .25rem .5rem;
      border-radius: $radius;
      font-size: .8rem;
      border: 1px solid $lightgrey;
      color: $lightgrey;
    }

    h1 {
      margin: 1rem 0 0rem 0;
    }

    h4 {
      font-weight: 400;
      margin: .25rem 0 2rem 0;
      color: $white;
    }

    img {
      max-height: 30vh;
    
      &#settings {
        position: fixed;
        top: 1rem;
        right: 1rem;
        width: 1.6rem;
        opacity: .6;
      }

      &#logo {
        max-height: 10vh;
      }
    }
  }

  .swipeformore {
    display: flex;
    justify-content: center;
    color: rgba($color: #FFF, $alpha: .4);
  }
}
</style>
